import React from "react";
import { useLocation } from "react-router-dom";
import AppLayout from "../../../layout/AppLayout";
import { Descriptions, Button } from "antd";
import useGetAllCoupons from "../../../hooks/useGetAllCoupons";
import "./influencerdetail.css";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Popconfirm, message } from "antd";
import { AiTwotoneDelete } from "react-icons/ai";
import axios from "axios";
import { useSelector } from "react-redux";

function InfluencerDetails() {
  const {
    state: {
      influencer: {
        email,
        platform,
        platformUrl,
        // userId,
        userName,
        userNameOnPlatform,
      },
    },
  } = useLocation();
  const I_id = useLocation().state.influencer._id;
  console.log("user of influnevcer ", I_id);
  const { user } = useSelector((state) => state.user);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [flag, setFlag] = useState(false);

  const coupons = useGetAllCoupons(flag);
  console.log("check in influer deatilks", coupons);

  // console.log ('user id from coupon ', coupons)

  const filteredUsers = coupons.filter((user) => user.existingUser);
  console.log("data after filter is ", filteredUsers);
  // let cid;
  // for (let i = 0; i < filteredUsers.length; i++) {
  //   cid = filteredUsers[i].existingUser;
  //   console.log("Coupon user id ", cid);
  // }
  // {
  //   I_id == cid ? console.log("hello") : console.log("eroor");
  // }
  const filteredcoupons = filteredUsers.filter(
    (coupen) => coupen.existingUser === I_id
  );
  console.log("filtercoupen +++++++++", filteredcoupons);
  // const showModal = () => {
  //   setIsModalOpen(true);
  // };
  // const handleOk = () => {
  //   setIsModalOpen(false);
  // };
  // const handleCancel = () => {
  //   setIsModalOpen(false);
  // };
  const navigate = useNavigate();
  const [showLoading, setShowLoading] = useState(true);

  const confirm = async (data, action) => {
    setShowLoading(true);
    try {
      const res = await axios.delete(
        process.env.REACT_APP_BASE_URL + `coupon/deleteCoupon/${data._id}`,
        {
          headers: {
            Authorization: `jwt ${window.atob(user.token)}`,
          },
        }
      );
      //     // const res = await axios.delete(
      //     //   process.env.REACT_APP_BASE_URL + `stripe/deleteCoupon?code=${data.id}`,
      //     //   {
      //     //     headers: {
      //     //       Authorization: `jwt ${window.atob(user.token)}`,
      //     //     },
      //     //   }
      //     // );
      if (res.data.status) {
        message.success(res.data.msg);
        setFlag(!flag);
      } else {
        message.error(res.data.msg);
      }

      setShowLoading(false);
    } catch (e) {
      console.log(e);
      setShowLoading(false);
    }
  };

  const cancel = (e) => {
    message.error("You are cancelled your changes!");
  };
  return (
    <AppLayout>
      <Button onClick={() => window.history.back()}>Back</Button>
      <Descriptions title="Influencer Details" layout="vertical" bordered>
        <Descriptions.Item label="Name">{userName}</Descriptions.Item>
        <Descriptions.Item label="Email">{email}</Descriptions.Item>
        <Descriptions.Item label="Platform">{platform}</Descriptions.Item>
        <Descriptions.Item label="Platform URL">
          <a onClick={() => window.open(platformUrl)}>{platformUrl}</a>
        </Descriptions.Item>
        <Descriptions.Item label="Influencer Name on Platform">
          {userNameOnPlatform}
        </Descriptions.Item>
      </Descriptions>

      <h2 style={{ marginTop: "20px", fontSize: "22px" }}>
        Influencer Coupons{" "}
      </h2>
      <div className="coupon-data">
        <table className="table-data">
          <tr style={{ color: "gray" }}>
            <th>Name</th>
            <th>Code</th>
            <th>Amount</th>
            <th>Expiry Date</th>
            <th>History</th>
            <th>Delete</th>
          </tr>

          {filteredcoupons?.map((p) => (
            <tr className="trr" key={p?._id}>
              <td>{p?.name}</td>
              <td>{p?.code}</td>
              <td>${p?.amount}</td>
              <td>{p?.validUntil.slice(0, 10)}</td>
              {/* <td>{p?.existingUser}</td> */}
              <button
                onClick={() => {
                  navigate(`/coupon-details/${p?._id}`);
                }}
                style={{
                  backgroundColor: "transparent",
                  textAlign: "center",
                  marginTop: "10px",
                  border: "none",
                  cursor: "pointer",
                }}
              >
                Show detail
              </button>
              <td>
                <Popconfirm
                  title="Are you sure about the delete action?"
                  onConfirm={() => confirm(p, "del")}
                  onCancel={cancel}
                  okText="Yes"
                  cancelText="No"
                  placement="left"
                >
                  <AiTwotoneDelete
                    style={{
                      cursor: "pointer",
                      color: "red",
                      fontSize: "20px",
                    }}
                  />
                </Popconfirm>
              </td>
            </tr>
          ))}
        </table>
      </div>
    </AppLayout>
  );
}

export default InfluencerDetails;

import React, { useState } from "react";
import AppLayout from "../../../../layout/AppLayout";
import "./productdetails.scss";
import { IoReturnUpBack } from "react-icons/io5";
import { useLocation, useNavigate } from "react-router-dom";
import profile from "../../../../assets/profile.png";
import { Row, Col } from "antd";
function ProductDetails() {
  const { state } = useLocation();
  const { productDetails } = state;
  console.log(productDetails);
  const navigate = useNavigate();
  return (
    <AppLayout>
      <div className="product-details">
        <div className="header">
          <IoReturnUpBack className="icon" onClick={() => navigate(-1)} />
        </div>
        <div className="details">
          <div className="heading">
            <h2>Product Details</h2>
          </div>
          <div className="details-card">
            <div className="heading">
              <img
                src={productDetails ? productDetails.image : profile}
                alt=""
              />
            </div>
            <Row>
              <Col lg={12} xs={24}>
                <div className="content-x">
                  <h3>
                    <b>Name:</b>
                  </h3>
                  <h4>{productDetails?.name}</h4>
                </div>
              </Col>
              <Col lg={12} xs={24}>
                <div className="content-x">
                  <h3>
                    <b>Description:</b>
                  </h3>
                  <h4>{productDetails?.description}</h4>
                </div>
              </Col>
            </Row>
            <Row>
              <Col lg={12} xs={24}>
                <div className="content-x">
                  <h3>
                    <b>Size:</b>
                  </h3>
                  <h4>{productDetails?.size}</h4>
                </div>
              </Col>
              <Col lg={12} xs={24}>
                <div className="content-x">
                  <h3>
                    <b>Price:</b>
                  </h3>
                  <h4>{productDetails?.price}</h4>
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </div>
    </AppLayout>
  );
}

export default ProductDetails;

import React from "react";
import useGetAllCoupons from "../../../hooks/useGetAllCoupons";
import { useParams } from "react-router-dom";
import "./coupondetail.css";

const CouponUserData = () => {
  const coupons = useGetAllCoupons();
  let filteredUsersCoupon = coupons.filter((user) => user.existingUser);
  const { id } = useParams();
  let filteredId = filteredUsersCoupon.filter((val) => val?._id === id);
  return (
    <>
      <div
        style={{
          backgroundColor: "#282828",
          height: "100%",
          width: "70%",
          display:'flex',
          justifyContent:"center",
          alignContent:'center',
          flexDirection:'column',
          margin:'auto',
          marginTop:'60px',
          color: "white",
          padding:'20px'

        }}
      >
        <h2 style={{textAlign:'center'}}>This Coupon Details</h2>
        {filteredId?.map((item) => {
          return (
            <>
              <div
                style={{
                  border: " 1px solid rgb(236, 228, 228)",
                  width: "90%",
                  margin: "auto",
                }}
              >
                <ul
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    width: "80%",
                    marginLeft: "10%",
                    fontSize: "28px",
                    listStyle: "none",
                    padding:'20px'
                  }}
                >
                  <li>Users Id</li>
                  <li>Used Date</li>
                </ul>
                {item?.usedBy.map((used) => {
                  return (
                    <>
                      <div>
                        <ul
                          style={{
                            textAlign: "center",
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                            listStyle: "none",
                            width: "80%",
                            marginLeft: "6%",
                            marginBottom: "60px",
                          }}
                        >
                          <li>{used.user}</li>
                          <li>{used.usedAt.slice(0,10)}</li>
                        </ul>
                      </div>
                    </>
                  );
                })}
              </div>
            </>
          );
        })}
      </div>
    </>
  );
};

export default CouponUserData;

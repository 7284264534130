import { Button, Descriptions, Image } from "antd";
import moment from "moment";
import React from "react";
import { useLocation } from "react-router-dom";
import AppLayout from "../../../layout/AppLayout";
function AllOrderDetails() {
  const { state } = useLocation();
  // console.log(useLocation());
  const { orderDetails } = state;
  // console.log('orderd details check ', orderDetails)


  return (
    <AppLayout>
      <Descriptions title="Order Info" bordered>
        <Descriptions.Item label="Order Date">
          {moment(orderDetails?.orderDate).format("DD-MM-YYYY")}
        </Descriptions.Item>
        <Descriptions.Item label="Order Type">
          {orderDetails?.status.toUpperCase()}
        </Descriptions.Item>
        <Descriptions.Item label="Order Number">
          {orderDetails?.orderNumber}
        </Descriptions.Item>
        <Descriptions.Item label="Album Name">
          {orderDetails?.albumName}
        </Descriptions.Item>
        <Descriptions.Item label="Album Cover Name">
          {orderDetails?.albumCoverName}
        </Descriptions.Item>
        <Descriptions.Item label="Ordered Product">
          {`${orderDetails?.pages} pages ${orderDetails?.size}`}
        </Descriptions.Item>
        <Descriptions.Item label="User Name" span={2}>
          {orderDetails?.userName}
        </Descriptions.Item>
        <Descriptions.Item label="Product Price">
          ${orderDetails?.productPrice}
        </Descriptions.Item>
        <Descriptions.Item label="Discount">
          {orderDetails?.discount}
        </Descriptions.Item>
        <Descriptions.Item label="Coupon Code">
          {orderDetails?.coupon}
        </Descriptions.Item>
        <Descriptions.Item label="Total Amount">
          ${orderDetails?.totalAmount}
        </Descriptions.Item>
        
      </Descriptions>

      <br />
      <Descriptions title="Order Ship To Addresses" bordered>
        {orderDetails?.addresses?.map((ad, index) => {
          // console.log('check prurpose',ad)
          return (
            <>
              <Descriptions.Item label={`Address #${index + 1}`} span={1}>
                {ad?.address}
              </Descriptions.Item>
              <Descriptions.Item label={`Status`} span={1}>
                {ad?.statusId}
              </Descriptions.Item>
              <Descriptions.Item label={`Tracking No`} span={1}>
                {/* {ad?.shippingData?.trackingNumber} */}
                {ad?.order_id}
              </Descriptions.Item>
              <Descriptions.Item label={`Quantity`} span={1}>
                {ad?.quantity}
              </Descriptions.Item>
              <Descriptions.Item label={`City`} span={1}>
                {ad?.city}
              </Descriptions.Item>
              <Descriptions.Item label={`State`} span={1}>
                {ad?.state}
              </Descriptions.Item>
              <Descriptions.Item label={`Zip Code`} span={1}>
                {ad?.zip}
              </Descriptions.Item>
              <Descriptions.Item label={`Shipping Charges`} span={1}>
                ${ad?.shippingValue}
              </Descriptions.Item>
            </>
          );
        })}
      </Descriptions>
      <br />

      {orderDetails?.filePath && (
        <Descriptions title="View Order File" bordered>
          <Descriptions.Item label={`File`}>
            <Button onClick={() => window.open(orderDetails?.filePath)}>
              Click to View File
            </Button>
          </Descriptions.Item>
          {orderDetails?.customAlbumCover ? (
            <Descriptions.Item label={`Custom Album Cover`}>
              <Button
                onClick={() => window.open(orderDetails?.customAlbumCover?.pdf)}
              >
                Click to View Custom Album Cover
              </Button>
            </Descriptions.Item>
          ) : (
            ""
          )}
        </Descriptions>
      )}
      <br />
      <b>
        <h2>Order Album Gallery</h2>
      </b>
      <Image.PreviewGroup>
        {orderDetails?.albumImages
          ?.sort((a, b) => a.id - b.id)
          .map((img) => (
            <Image width={250} src={img.url} loading="lazy" />
          ))}
      </Image.PreviewGroup>
    </AppLayout>
  );
}

export default AllOrderDetails;

import React, { useState } from "react";
import useAllInfluencer from "../../../hooks/useAllInfluencer";
import AppLayout from "../../../layout/AppLayout";
import "../influencers.scss";
import {
  Row,
  Col,
  Empty,
  Button,
  Pagination,
} from "antd";
import { AiOutlineSearch } from "react-icons/ai";
import { Link, useNavigate } from "react-router-dom";
import FullPageLoading from "../../../components/Loading/FullPageLoading";
export const Influencer = () => {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [fullloading, setfullLoading] = useState(true);
  
    const users = useAllInfluencer(loading);

    const [search, setSearch] = useState("");
    const [count, setCount] = useState({ minVal: 0, maxVal: 8 });
    const uniqueUsers = (data) => {
      let keys = ["userNameOnPlatform", "userName", "email"];
      return data?.filter((us) => {
        if (keys.some((k) => us[k]?.toLowerCase().includes(search.toLowerCase())))
          return us;
      });
    };
    const numEachPage = 8;
    const handleChange = (value) => {
      setCount({
        minVal: (value - 1) * numEachPage,
        maxVal: value * numEachPage,
      });
    };
    setTimeout(() => {
      uniqueUsers()
      setfullLoading(false);
    }, );

  return (
    <AppLayout>
      <div className="influencerRequests">
        <div className="search-row">
          <h3>
            <b>Total Number of Influencers ({users?.length})</b>
          </h3>
          <div className="search">
            <input
              type="text"
              className="search-input"
              placeholder="search..."
              onChange={(e) => setSearch(e.target.value)}
            />
            <AiOutlineSearch className="icon" />
            <button>Search</button>
          </div>
        </div>
        <div className="header-row">
          <Row className="">
            <Col span={4}>
              <p>
                <b>User Name</b>
              </p>
            </Col>
            <Col span={5}>
              <p>
                <b>Email</b>
              </p>
            </Col>
            <Col span={4}>
              <p>
                <b>User Name On Platform</b>
              </p>
            </Col>
            <Col span={3}>
              <p>
                <b>Platform</b>
              </p>
            </Col>
            <Col span={6}>
              <p>
                <b>Platform URL</b>
              </p>
            </Col>

            <Col span={2}>
              <p>
                <b>Details</b>
              </p>
            </Col>
          </Row>
        </div>

        {uniqueUsers(users)?.length > 0 ? (
          uniqueUsers(users)
            .slice(count.minVal, count.maxVal)
            .map((u) => {
              return (
                <Row className="row-e" key={u?._id}>
                  <Col xs={24} lg={4} sm={12}>
                    <div className="e-cell">
                      <div className="cell-title">
                        <p>
                          <b>User Name</b>
                        </p>
                      </div>
                      <div className="cell-content">{u?.userName}</div>
                      {/* {console.log(u?.userName)} */}
                    </div>
                  </Col>
                  <Col xs={24} lg={5} sm={12}>
                    <div className="e-cell">
                      <div className="cell-title">
                        <p>
                          <b>Platform</b>
                        </p>
                      </div>
                      <div className="cell-content">{u?.email}</div>
                    </div>
                  </Col>
                  <Col xs={24} lg={4} sm={12}>
                    <div className="e-cell">
                      <div className="cell-title">
                        <p>
                          <b>User Name On Platform</b>
                        </p>
                      </div>
                      <div className="cell-content">
                        {u?.userNameOnPlatform}
                      </div>
                    </div>
                  </Col>
                  <Col xs={24} lg={3} sm={12}>
                    <div className="e-cell">
                      <div className="cell-title">
                        <p>
                          <b>Platform</b>
                        </p>
                      </div>
                      <div className="cell-content">{u?.platform}</div>
                    </div>
                  </Col>
                  <Col xs={24} lg={6} sm={12}>
                    <div className="e-cell">
                      <div className="cell-title">
                        <p>
                          <b>Platform Url</b>
                        </p>
                      </div>
                      <div className="cell-content">
                        <Link  onClick={() => window.open(u.platformUrl)}>
                          {u?.platformUrl}
                        </Link>
                      </div>
                    </div>
                  </Col>

                  <Col xs={24} lg={2} sm={12}>
                    <div className="e-cell">
                      <div className="cell-title">
                        <p>
                          <b>Details</b>
                        </p>
                      </div>
                      <div className="cell-content">
                        <Button
                          onClick={() =>
                            navigate("/influencer-details", {
                              state: { influencer: u },
                            })
                          }
                        >
                          View
                        </Button>
                      </div>
                    </div>
                  </Col>
                </Row>
              );
            })
        ) : (
          <>
            {fullloading && <FullPageLoading />}
            <Empty />
          </>
        )}
        {users.length > 0 ? (
          <Pagination
            defaultCurrent={1}
            defaultPageSize={numEachPage}
            onChange={handleChange}
            total={users.length}
          />
        ) : null}
      </div>
    </AppLayout>
  )
}

import {
  AiOutlineOrderedList,
  AiOutlineUsergroupDelete,
  AiFillGift,
} from "react-icons/ai";
import { FaSitemap } from "react-icons/fa";
import { MdNotificationAdd } from "react-icons/md";
export const MenueItems = [
  {
    key: 1,
    label: "Users",
    icon: <AiOutlineUsergroupDelete className="icon" />,
    path: "/users",
  },
  {
    key: 2,
    label: "Products",
    icon: <AiOutlineOrderedList className="icon" />,
    path: "/products",
  },
  {
    key: 3,
    label: "Promos",
    icon: <AiFillGift className="icon" />,
    path: "/promos",
  },
  {
    key: 4,
    label: "All Orders",
    icon: <FaSitemap className="icon" />,
    path: "/all-orders",
  },
  {
    key: 5,
    label: "Influencer Requests",
    icon: <MdNotificationAdd className="icon" />,
    path: "/influencer-requests",
  },
  {
    key: 6,
    label: "All Influencers",
    icon: <FaSitemap className="icon" />,
    path: "/influencers",
  },
];

import React, { useState } from "react";
import useGetAllInfluencerRequests from "../../hooks/useGetAllInfluencerRequests";
import AppLayout from "../../layout/AppLayout";
import "./influencerRequest.scss";
import {
  message,
  Row,
  Col,
  Switch,
  Empty,
  Popconfirm,
  Pagination,
  Space,
} from "antd";
import { AiOutlineSearch, AiTwotoneDelete } from "react-icons/ai";
import { CheckOutlined, CloseOutlined } from "@ant-design/icons";
import { BiEdit } from "react-icons/bi";
import { useNavigate } from "react-router-dom";
import FullPageLoading from "../../components/Loading/FullPageLoading";
import { acceptInfluencer, removeInfluencer } from "../../APIs/DatabaseApis";
function InfluencerRequests() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [fullloading, setfullLoading] = useState(true);

  const users = useGetAllInfluencerRequests(loading);
  const [search, setSearch] = useState("");
  const [count, setCount] = useState({ minVal: 0, maxVal: 8 });
  const uniqueUsers = (data) => {
    // console.log("data is ", data);
    let keys = ["userNameOnPlatform", "userName", "email"];
    return data.filter((us) => {
      if (keys.some((k) => us[k].toLowerCase().includes(search.toLowerCase())))
        return us;
    });
  };
  const confirm = async (data, action) => {
    try {
      const res = await removeInfluencer(data._id);
      setLoading(!loading);
    } catch (e) {}
  };

  const cancel = (e) => {
    message.error("You are cancelled your changes!");
  };
  const numEachPage = 8;
  const handleChange = (value) => {
    setCount({
      minVal: (value - 1) * numEachPage,
      maxVal: value * numEachPage,
    });
  };
  setTimeout(() => {
    setfullLoading(false);
  }, 5000);
  return (
    <AppLayout>
      <div className="influencerRequests">
        <div className="search-row">
          <h3>
            <b>Total Number of Influencers Request ({users?.length})</b>
          </h3>
          <div className="search">
            <input
              type="text"
              className="search-input"
              placeholder="search..."
              onChange={(e) => setSearch(e.target.value)}
            />
            <AiOutlineSearch className="icon" />
            <button>Search</button>
          </div>
        </div>
        <div className="header-row">
          <Row className="">
            <Col span={4}>
              <p>
                <b>User Name</b>
              </p>
            </Col>
            <Col span={5}>
              <p>
                <b>Email</b>
              </p>
            </Col>
            <Col span={4}>
              <p>
                <b>User Name On Platform</b>
              </p>
            </Col>
            <Col span={3}>
              <p>
                <b>Platform</b>
              </p>
            </Col>
            <Col span={4}>
              <p>
                <b>Platform URL</b>
              </p>
            </Col>
            <Col span={2}>
              <p>
                <b>Accepted</b>
              </p>
            </Col>
            <Col span={2}>
              <p>
                <b>Delete</b>
              </p>
            </Col>
          </Row>
        </div>

        {uniqueUsers(users)?.length > 0 ? (
          uniqueUsers(users)
            .slice(count.minVal, count.maxVal)
            .map((u) => {
              return (
                <Row className="row-e" key={u?._id}>
                  <Col xs={24} lg={4} sm={12}>
                    <div className="e-cell">
                      <div className="cell-title">
                        <p>
                          <b>User Name</b>
                        </p>
                      </div>
                      <div className="cell-content">{u?.userName}</div>
                    </div>
                  </Col>
                  <Col xs={24} lg={5} sm={12}>
                    <div className="e-cell">
                      <div className="cell-title">
                        <p>
                          <b>Platform</b>
                        </p>
                      </div>
                      <div className="cell-content">{u?.email}</div>
                    </div>
                  </Col>
                  <Col xs={24} lg={4} sm={12}>
                    <div className="e-cell">
                      <div className="cell-title">
                        <p>
                          <b>User Name On Platform</b>
                        </p>
                      </div>
                      <div className="cell-content">
                        {u?.userNameOnPlatform}
                      </div>
                    </div>
                  </Col>
                  <Col xs={24} lg={3} sm={12}>
                    <div className="e-cell">
                      <div className="cell-title">
                        <p>
                          <b>Platform</b>
                        </p>
                      </div>
                      <div className="cell-content">{u?.platform}</div>
                    </div>
                  </Col>
                  <Col xs={24} lg={4} sm={12}>
                    <div className="e-cell">
                      <div className="cell-title">
                        <p>
                          <b>Platform Url</b>
                        </p>
                      </div>
                      <div className="cell-content">
                        <a onClick={() => window.open(u.platformUrl)}>
                          {u?.platformUrl}
                        </a>
                      </div>
                    </div>
                  </Col>
                  <Col xs={24} lg={2} sm={12}>
                    <div className="e-cell">
                      <div className="cell-title">
                        <p>
                          <b>Accepted</b>
                        </p>
                      </div>
                      <div className="cell-content">
                        <Space>
                          <Switch
                            checked={
                              u?.isAccepted !== undefined &&
                              u?.isAccepted === true
                            }
                            checkedChildren={<CheckOutlined />}
                            unCheckedChildren={<CloseOutlined />}
                            onChange={async (value) => {
                              try {
                                const res = await acceptInfluencer(u._id);
                                if (res.status) {
                                  setLoading(!loading);
                                }
                              } catch (e) {}
                            }}
                          />
                        </Space>
                      </div>
                    </div>
                  </Col>
                  <Col xs={24} lg={2} sm={12}>
                    <div className="e-cell">
                      <div className="cell-title">
                        <p>
                          <b>Delete</b>
                        </p>
                      </div>
                      <div className="cell-content">
                        <Popconfirm
                          title="Are you sure about the delete action?"
                          onConfirm={() => confirm(u, "del")}
                          onCancel={cancel}
                          okText="Yes"
                          cancelText="No"
                          placement="left"
                        >
                          <AiTwotoneDelete className="icon del" />
                        </Popconfirm>
                      </div>
                    </div>
                  </Col>
                </Row>
              );
            })
        ) : (
          <>
            {fullloading && <FullPageLoading />}
            <Empty />
          </>
        )}
        {users.length > 0 ? (
          <Pagination
            defaultCurrent={1}
            defaultPageSize={numEachPage}
            onChange={handleChange}
            total={users.length}
          />
        ) : null}
      </div>
    </AppLayout>
  );
}

export default InfluencerRequests;

import React, { useState, useEffect } from "react";
import useGetAllOrders from "../../hooks/useGetAllOrders";
import useGetAllUsers from "../../hooks/useGetAllUsers";
import AppLayout from "../../layout/AppLayout";
import "./allorders.scss";
import {
  Row,
  Col,
  Empty,
  Segmented,
  Modal,
  Input,
  Form,
  Button,
  Pagination,
} from "antd";
import { AiOutlineSearch } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import SimpleLoading from "../../components/Loading/Loader";
import axios from "axios";
import moment from "moment";
import FullPageLoading from "../../components/Loading/FullPageLoading";
import { FcApproval, FcPaid, FcMindMap } from "react-icons/fc";
import { placeOrderFromAdmin } from "../../APIs/DatabaseApis";
function AllOrders() {
  const user = JSON.parse(localStorage.getItem("user"));
  const [form] = Form.useForm();

  const [orders, setOrders] = useState([]);
  const navigate = useNavigate();
  const [updateTrigger, setUpdateTrigger] = useState(false);

  const users = useGetAllUsers();
  const [search, setSearch] = useState("");
  const [showLoading, setShowLoading] = useState(true);
  const [listener, setListener] = useState(0);
  const [updatedOrder, setUpdatedOrder] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [sortOrder, setSortOrder] = useState("order");
  const [paginationFilters, setPaginationFilters] = useState({
    pageNo: 1,
    pageSize: 10,
    limitSkip: 0,
    limit: 10,
  });
  const { orders: ordersX, count: countsX } = useGetAllOrders(
    updateTrigger,
    paginationFilters
  );
  ///pagination
  // const numEachPage = 10;
  const [count, setCount] = useState(0);
  console.log("🚀 ~ file: AllOrders.jsx:52 ~ AllOrders ~ count:", count);

  const handleChange = (value, pageSize) => {
    const limitSkip = value * pageSize;
    const limit = pageSize;
    setPaginationFilters({ pageNo: value, pageSize, limitSkip, limit });
    console.log(
      "🚀 ~ file: AllOrders.jsx:55 ~ handleChange ~ value:",
      value,
      pageSize
    );
    setUpdateTrigger(!updateTrigger);

    // const { orders: ordersX, count: countsX } = useGetAllOrders(updateTrigger);

    // setCount({
    //   minVal: (value - 1) * numEachPage,
    //   maxVal: value * numEachPage,
    // });
  };

  // const onShowSizeChange = (value) => {
  //   console.log("🚀 ~ file: AllOrders.jsx:63 ~ onShowSizeChange ~ value:", value)
  //   // setCount({
  //   //   minVal: (value - 1) * numEachPage,
  //   //   maxVal: value * numEachPage,
  //   // });
  // };
  const uniqueOrders = (data) => {
    let keys = ["userName", "orderStatus"];
    return data?.filter((ps) => {
      if (
        keys?.some((k) => ps[k]?.toLowerCase()?.includes(search?.toLowerCase()))
      )
        return ps;
    });
  };

  const reorderToZendirect = async (order) => {
    try {
      setShowLoading(true);
      const psUser = users.find((v) => v._id === order?.userId);
      // await backgroundPlaceOrderapi(psUser, order);
      await placeOrderFromAdmin(order, psUser);
      setUpdateTrigger(!updateTrigger);
      setShowLoading(false);
    } catch (error) {
      setShowLoading(false);
    }
  };
  const handleChangeSelect = async (value, order) => {
    setShowLoading(true);
    try {
      const res = await axios.post(
        process.env.REACT_APP_BASE_URL + "order/updateOrder",
        {
          id: order._id,
          token: window.atob(user.token),
          orderStatus: value,
        }
      );
      if (res) {
        setShowLoading(false);
      } else {
        setShowLoading(false);
      }
    } catch (e) {
      console.log(e);
      setShowLoading(false);
    }
  };
  setTimeout(() => {
    if (orders.length > 0) {
      setShowLoading(false);
    } else {
    }
  });

  const handleTrackingNo = async (values) => {
    if (Object.keys(updatedOrder).length > 0) {
      setShowLoading(true);
      try {
        const res = await axios.post(
          process.env.REACT_APP_BASE_URL + "order/updateOrder",
          {
            id: updatedOrder._id,
            token: window.atob(user.token),
            trackingNo: Number(values.trackingNo),
          }
        );
        if (res) {
          setShowLoading(false);
          setListener((listener) => listener++);
          setShowModal(false);
          let arr = JSON.parse(JSON.stringify(orders));
          let o = {
            ...updatedOrder,
            trackingNo: Number(values.trackingNo),
          };
          let index = orders.findIndex((ox) => ox._id === o._id);
          arr.splice(index, 1, o);
          setOrders([...arr]);
          form.resetFields();
        } else {
          setShowLoading(false);
          setShowModal(false);
        }
      } catch (e) {
        console.log(e);
        setShowLoading(false);
        setShowModal(false);
      }
    }
  };

  useEffect(() => {
    if (sortOrder === "order") {
      let o = ordersX.filter((val) => val.status === "order");
      setOrders([...o]);
    } else if (sortOrder === "approve") {
      let o = ordersX.filter((val) => val.status === "approve");
      setOrders([...o]);
    } else if (sortOrder === "zenprint") {
      let o = ordersX.filter((val) => val.orderPlaceToZendirect);
      setOrders([...o]);
    } else {
      setOrders(ordersX);
    }
    setCount(countsX);
  }, [JSON.stringify(ordersX), sortOrder]);

  return (
    <AppLayout>
      {showLoading ? <FullPageLoading /> : null}
      {showModal ? (
        <Modal
          open={showModal}
          okText="Save"
          // onOk={() => handleTrackingNo()}
          onCancel={() => setShowModal(false)}
          footer={[
            <Button form="myForm" key="submit" htmlType="submit">
              Save
            </Button>,
          ]}
        >
          <br />
          <Form
            form={form}
            layout="vertical"
            onFinish={handleTrackingNo}
            id="myForm"
          >
            <Form.Item
              label="Tracking#"
              name="trackingNo"
              rules={[
                {
                  required: true,
                  message: "Tracking No is required",
                },
              ]}
            >
              <Input
                className="input-primary"
                placeholder="Tracking Number"
                type="number"
              />
            </Form.Item>
          </Form>
        </Modal>
      ) : null}
      <div className="all-orders">
        <div className="search-row">
          <h2>
            <b>Total Number of Orders ({orders?.length})</b>
          </h2>

          <div className="search">
            <input
              type="text"
              className="search-input"
              placeholder="search..."
              onChange={(e) => setSearch(e.target.value)}
            />
            <AiOutlineSearch className="icon" />
            <button>Search</button>
          </div>
        </div>
        <Segmented
          onChange={(e) => setSortOrder(e)}
          options={[
            {
              label: (
                <div className="d-flex">
                  <FcApproval className="icon-d" />
                  Successfull Orders
                </div>
              ),
              value: "order",
            },
            {
              label: (
                <div className="d-flex">
                  <FcMindMap className="icon-d" />
                  Approved Orders
                </div>
              ),
              value: "approve",
            },
            {
              label: (
                <div className="d-flex">
                  <FcPaid className="icon-d" />
                  Placed to Zendirect
                </div>
              ),
              value: "zenprint",
            },
          ]}
        />
        <div className="header-row">
          <Row className="">
            <Col span={2}>
              <p>
                <b>Date</b>
              </p>
            </Col>
            <Col span={2}>
              <p>
                <b>Order Id</b>
              </p>
            </Col>
            <Col span={3}>
              <p>
                <b>User Name</b>
              </p>
            </Col>
            <Col span={3}>
              <p>
                <b>Product Ordered</b>
              </p>
            </Col>
            <Col span={2}>
              <p>
                <b>Quantity</b>
              </p>
            </Col>
            <Col span={5}>
              <p>
                <b>Ship To Name</b>
              </p>
            </Col>
            <Col span={3}>
              <p>
                <b>Order Type</b>
              </p>
            </Col>
            <Col span={2}>
              <p>
                <b>ZenDirect Order</b>
              </p>
            </Col>
            <Col span={2}>
              <p>
                <b>Details</b>
              </p>
            </Col>
          </Row>
        </div>

        {uniqueOrders(orders)?.length > 0 ? (
          <>
            {uniqueOrders(orders).map((p) => {
              return (
                <Row className="row-e" key={p?.id}>
                  <Col xs={24} lg={2} sm={12}>
                    <div className="e-cell">
                      <div className="cell-title">
                        <p>
                          <b>Date</b>
                        </p>
                      </div>
                      <div className="cell-content">
                        {moment(p?.createdAt).format("DD-MM-YYYY")}
                      </div>
                    </div>
                  </Col>
                  <Col xs={24} lg={2} sm={12}>
                    <div className="e-cell">
                      <div className="cell-title">
                        <p>
                          <b>Order ID</b>
                        </p>
                      </div>
                      <div className="cell-content">
                        {p?.orderNumber ? p?.orderNumber : "N/A"}
                      </div>
                    </div>
                  </Col>
                  <Col xs={24} lg={3} sm={12}>
                    <div className="e-cell">
                      <div className="cell-title">
                        <p>
                          <b>User Name</b>
                        </p>
                      </div>
                      <div className="cell-content">{p?.userName}</div>
                    </div>
                  </Col>
                  <Col xs={24} lg={3} sm={12}>
                    <div className="e-cell">
                      <div className="cell-title">
                        <p>
                          <b>Product Ordered</b>
                        </p>
                      </div>
                      <div className="cell-content">{`${p.pages} Pages ${p.size}`}</div>
                    </div>
                  </Col>
                  <Col xs={24} lg={2} sm={12}>
                    <div className="e-cell">
                      <div className="cell-title">
                        <p>
                          <b>Quantity</b>
                        </p>
                      </div>
                      <div className="cell-content">
                        <span
                          style={{
                            textAlign: "center",

                            width: "50%",
                          }}
                        >
                          {p?.addresses?.reduce(
                            (acc, val) => acc + val.quantity,
                            0
                          )}
                        </span>
                      </div>
                    </div>
                  </Col>
                  <Col xs={24} lg={5} sm={12}>
                    <div className="e-cell">
                      <div className="cell-title">
                        <p>
                          <b>Ship To Name</b>
                        </p>
                      </div>
                      <div className="cell-content">
                        {p?.addresses?.map((a) => a.name).join(", ")}
                      </div>
                    </div>
                  </Col>
                  <Col xs={24} lg={3} sm={12}>
                    <div className="e-cell">
                      <div className="cell-title">
                        <p>
                          <b>Order Type</b>
                        </p>
                      </div>
                      <div className="cell-content">
                        <span>{p?.status.toUpperCase()}</span>
                        {console.log(p)}
                        {/* <Select
                        onChange={(e) => handleChangeSelect(e, p)}
                        defaultValue={
                          p?.orderStatus ? p.orderStatus : "Pending"
                        }
                        disabled
                        style={{
                          width: 130,
                        }}
                        options={[
                          { value: "Pending", label: "Pending" },
                          { value: "Printing", label: "Printing" },
                          { value: "Shiped", label: "Shiped" },
                          { value: "Cancelled", label: "Cancelled" },
                        ]}
                      ></Select> */}
                      </div>
                    </div>
                  </Col>
                  <Col xs={24} lg={2} sm={12}>
                    <div className="e-cell">
                      <div className="cell-title">
                        <p>
                          <b>ZenDirect Order</b>
                        </p>
                      </div>
                      <div className="cell-content">
                        <span style={{ width: "50%" }}>
                          {p?.status === "order" ? (
                            <span>
                              {p?.orderPlaceToZendirect ? (
                                <span>Success</span>
                              ) : !p?.updatedAt ||
                                moment(p?.updatedAt)
                                  .add(10, "minutes")
                                  .format("lll") < moment().format("lll") ? (
                                <Button
                                  onClick={() => {
                                    reorderToZendirect(p);
                                  }}
                                >
                                  Order
                                </Button>
                              ) : (
                                <Button disabled>Processing</Button>
                              )}
                            </span>
                          ) : (
                            "N/A"
                          )}
                        </span>
                        {/* <BiEdit
                        className="icon"
                        onClick={() => {
                          setShowModal(true);
                          setUpdatedOrder(p);
                        }}
                      /> */}
                      </div>
                    </div>
                  </Col>
                  <Col xs={24} lg={2} sm={12}>
                    <div className="e-cell">
                      <div className="cell-title">
                        <p>
                          <b>Order</b>
                        </p>
                      </div>
                      <div className="cell-content">
                        <span
                          className="view-order"
                          style={{ marginLeft: "10px" }}
                          onClick={() =>
                            navigate("/all-orders/details/" + p._id, {
                              state: { orderDetails: p },
                            })
                          }
                        >
                          View
                        </span>
                      </div>
                    </div>
                  </Col>
                </Row>
              );
            })}
            {/* <Pagination
              defaultPageSize={20}
              current={current}
              onChange={onChange}
            /> */}
            <Pagination
              defaultCurrent={1}
              defaultPageSize={paginationFilters.pageSize}
              // onShowSizeChange={onShowSizeChange}
              onChange={handleChange}
              total={count}
            />
          </>
        ) : (
          <>
            {showLoading ? <SimpleLoading /> : null}
            <Empty />
          </>
        )}
      </div>
    </AppLayout>
  );
}

export default AllOrders;
